<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { parseErrors} from "@/helpers"
import { popModal, pushModal } from "@/modals";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
  
  },
  props: {
    id:{
       type: String,
       required: true
    }
  },
  data() {
    return {
      title: null,
      errors: [],
      item: {
        name: '',
        users: []
      }
    };
  },
  validations() {
    const rules = {
      item: {
        name: { required }
      }
    };
    return rules;
  },
  created(){
    this.title =this.isNew ? this.$t('common.newRecord') : this.$t('common.editRecord');
    this.init();
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    isNew () {
      return this.id.toLowerCase().trim() === 'new';
    },
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('userGroups', {
      loading: 'loading',
      deleting: 'deleting',
      processing: 'processing'
    }),
    ...mapGetters('users', {
      users: 'items'
    }),
  },
  methods: {
    ...mapActions('userGroups', {
      load: 'load',
      create: 'create',
      update: 'update',
    }),
    async close(res) {
       var modal = Modal.getInstance(this.$refs.modal);
       modal.hide();
      popModal();
       this.$emit("close", res);
    },
    selectUser(user, value) {
      console.log('aa', value);
      if(value)
        this.item.users = [...this.item.users, user.id];
      else
        this.item.users = [...this.item.users.filter(x => x !== user.id)];
    },
    async init(){
      if(!this.isNew) {
        const dto = await this.load( { id: this.id });
        const {
          name,
          users
        } = dto;
        this.item = { name, users: users.map(x => x.id )};
      }
    },
    async submit () {
      const isValid = await this.v$.$validate();
      console.log('submit', {isValid});
      if(isValid) {
        this.errors = [];
        const dto = {...this.item };
        let req = this.isNew ? this.create({ dto }) : this.update({ id: this.id, dto: dto});
        req.then(() => {
          this.$toast.open({message: this.$t('common.savedText')});
          this.close(true);
        })
        .catch(error => {
          this.errors = parseErrors(error);
        });
      }
    }
  }
};
</script>

<template>
  <div
    class="modal"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{title}}<i class="fa fa-spin fa-spinner ms-2" v-if="loading"/>
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>

            <div class="mb-4">
              <label for="name-input" class="col-form-label">{{ $t("userGroups.nameField") }}</label>
              <div >
                <input 
                  type="text" 
                  class="form-control" 
                  id="name-input" 
                  v-model="item.name"
                  :class="{ 'is-invalid': v$.item.name.$error }"
                />
                <div class="invalid-feedback">
                   {{ $t("common.pleaseEnter") }} {{ $t("userGroups.nameField") }}
                </div> 
              </div>
            </div>
            <div class="mb-4">
              <label for="email-input" class="col-form-label">{{ $t("userGroups.usersField") }}</label>
              <div>
                <div v-for="user in users" :key="user.id"> 
                  <input type="checkbox" class="px-2 me-2" :checked="item.users.indexOf(user.id) >= 0" @change="($event) => selectUser(user, $event.target.checked)">{{user.name}}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer row">
          <div class="col">
            <button
              type="button"
              class="btn btn-light"
              @click="close"
            >
              {{$t('common.closeButtonTitle')}}
            </button>
          </div>
          <div class="col text-end">
            <button type="button" class="btn btn-primary"
              :disabled="processing || loading" 
              @click="submit"
            >
            <i class="fa fa-spin fa-spinner me-2" v-if="processing"/>
            <i class="fa fa-save me-2" v-if="!processing"/>
              {{$t('common.saveButtonTitle')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
