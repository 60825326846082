<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { mapGetters,mapActions } from 'vuex';
import Swal from 'sweetalert2'
import EditForm from "./edit-form"
import { parseErrors} from "@/helpers"

export default {
  page: {
    title: "Users",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    EditForm
  },
  data() {
    return {
      initializing: true,
      keyword: '',
      editItemId: null
    };
  },
  created(){
    this.init();
    
  },
  mounted() {
    document.title = this.pagetitle;
  },
  computed :{
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('userGroups', {
      loading: 'loading',
      deleting: 'deleting',
      items: 'items',
      settings: 'settings'
    }),
    pagetitle() {
      return this.$t('userGroups.pageTitle')
    },
    isBusy () {
      return this.loading || this.initializing;
    },
    mode: {
      get: function() {
        return (this.settings || {}).mode || 'list';
      },
      set: function(newValue) {
        this.writeSettings({...this.settings, mode: newValue});
      }
    },
    filteredItems () {
      let a = [...this.items];
      if(this.keyword) {
        a = a.filter(x => x.name.includes(this.keyword));
      }
      return a;
    },
    allowEdit () {
      return (this.currentUser || {}).isAdmin;
    }
  },
  methods: {
    ...mapActions('userGroups', {
      delete: 'delete',
      writeSettings: 'writeSettings',
      loadSettings: 'loadSettings'
    }),
    async init() {
      this.initializing = true;
      await this.loadSettings();
      this.initializing = false;
    },
    tryToDelete(group) {
       Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.yesDeleteIt')
      }).then(result => {
        if (result.value) {
          this.delete({id: group.id}).then(()=>{
            Swal.fire(this.$t('common.deletedTitle'), this.$t('common.deletedText'), "success");
          }).catch((error)=>{
            const errors = parseErrors(error);
            Swal.fire(this.$t('common.cannotDelete'), errors[0], "warning");
          });
        }
      });
    },
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="isBusy">
   <edit-form v-if="editItemId" @close="editItemId = null" :id="editItemId">
    </edit-form>
    <div class="row align-items-center">
      <div class="col-md-12">
        <div
          class="d-flex flex-wrap align-items-center justify-content-end "
        >
          <div>
           <button
            v-if="currentUser.isAdmin"
              type="button"
              class="btn btn-primary"
              @click="editItemId = 'new'"
              >
                <eva-icon class="icon nav-icon me-1" name="plus-outline"></eva-icon>
                <div class="text-sm-end d-none d-md-inline-block ms-2">  {{ $t('common.newRecord') }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row" v-if="!initializing">
      <div class="col">
        <div class="table-responsive">
          <table
            class="table project-list-table  align-middle table-borderless"
            style="margin-bottom:100px"
          >
            <thead>
              <tr>
                <th v-if="currentUser.isAdmin" scope="col-auto text-nowrap"></th>
                <th scope="col-6">{{$t('userGroups.nameField')}}</th>
                <th scope="col-6">{{$t('userGroups.usersField')}}</th>
                <th  v-if="currentUser.isAdmin" scope="col-auto"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in filteredItems" :key="item.id">
                <td v-if="currentUser.isAdmin" class="text-nowrap">
                  <a  v-if="!item.everyone" href="javascript:void(0)" class="btn btn-light btn-sm" @click="editItemId = item.id">
                    <i class="bx bx-pencil me-1"></i> {{ $t("common.editItem") }}
                  </a>
                </td>
                <td>
                  <span v-if="item.everyone">{{ $t("userGroups.everyOneGroupName") }}</span>
                  <span v-if="!item.everyone">{{item.name}}</span>
                </td>
                <td>
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item mx-2 bg-light py-1 px-2 mb-1" v-for="user in item.users" :key="user.id">
                      {{user.name}}
                    </li>
                  </ul>
                </td>
               
                <td v-if="currentUser.isAdmin" scope="col-auto" class="text-end text-nowrap">
                  <a
                    v-if="!item.everyone"
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                    @click="tryToDelete(item)"
                    class="px-2 text-danger btn btn-light btn-sm"
                    >
                    <i class="bx bx-trash-alt me-1"></i>
                  
                    {{ $t("directory.deleteItem") }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
